<template>
  <div>
    <work-view-detail :result="result" :is-show-loading="isShowLoading"/>
    <work-view-date-of-work :result="result" :is-show-loading="isShowLoading"/>
    <work-view-contractor :result="result" :is-show-loading="isShowLoading" @work-update="getData"/>
    <work-view-jsa :work-id="id" :card-collapsed="true"/>
    <work-view-safety-check :work-id="id" :card-collapsed="true" @total-safety-row="setTotalSafetyRow"/>
    <work-view-checkin-history :work-id="id" :card-collapsed="true"/>
    <work-view-activity :work-id="id" :card-collapsed="true"/>

    <b-row class="mt-3 mb-5" v-if="!isShowLoading">
      <b-col md="5" order="2" order-md="1">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-start">
          <b-button variant="danger" class="mb-1 mr-0 mr-md-2 order-1 order-md-2" :block="setBtnBlock"
                    :size="setBtnSize" v-if="stateBtnExtend && !result.workStartDateExtend && [1].includes(userData.userGroupId)"
                    :to="{name: 'pageWorkExtend', param: {id: id}}"
          >
            <feather-icon icon="ClockIcon" :size="setIconSize"/>
            {{ $t('work.btnExtend') }}
          </b-button>
          <b-button variant="success" class="mb-1 mr-0 mr-md-2 order-2 order-md-1" :block="setBtnBlock"
                    :size="setBtnSize" v-if="result.workStep === 20 && [1].includes(userData.userGroupId)"
                    :to="{name: 'pageWorkFinish', param: {id: id}}"
                    :disabled="!stateBtnFinish"
          >
            <feather-icon icon="ArchiveIcon" :size="setIconSize"/>
            {{ $t('work.btnFinish') }}
          </b-button>
          <b-button variant="success" class="mb-1 mr-0 mr-md-2 order-2 order-md-1" :block="setBtnBlock"
                    :size="setBtnSize" v-if="result.workStep === 21 && [4].includes(userData.userGroupId)"
                    :to="{name: 'pageWorkFinish', param: {id: id}}"
					          :disabled="!stateBtnFinish"
          >
            <feather-icon icon="ArchiveIcon" :size="setIconSize"/>
            {{ $t('work.btnFinish') }}
          </b-button>
        </div>

      </b-col>
      <b-col md="7" class="text-right" order="1" order-md="1">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-end">
          <div class="align-self-stretch align-self-md-center order-2 order-md-1">
            <b-button variant="warning" class="mb-1 mr-1" :block="setBtnBlock" :size="setBtnSize"
                      v-if="stateBtnSafety && [1, 2].includes(userData.userGroupId) && stateBtnCheckOut && result.workLatestIsCheckIn === 'Y'"
                      :to="{name: 'pageWorkSafetyCheck', param: {id: id}, query: {type: 'during'}}"
            >
              <feather-icon icon="CheckSquareIcon" :size="setIconSize"/>
              {{ $t('work.btnSafetyCheck') }}
            </b-button>
            <b-button variant="warning" class="mb-1 mr-1" :block="setBtnBlock" :size="setBtnSize"
                      v-if="stateBtnSafety && [1, 2].includes(userData.userGroupId) && checkoutIsSameDay && result.workLatestIsCheckIn === 'N'"
                      :to="{name: 'pageWorkSafetyCheck', param: {id: id}, query: {type: 'after'}}"
            >
              <feather-icon icon="CheckSquareIcon" :size="setIconSize"/>
              {{ $t('work.btnSafetyCheck') }}
            </b-button>
            <b-button variant="warning" class="mb-1 mr-1" :block="setBtnBlock" :size="setBtnSize" v-if="stateBtnSafetyCrossCheck && [4].includes(userData.userGroupId)"
                      :to="{name: 'pageWorkSafetyCrossCheck', param: {id: id}}"
            >
              <feather-icon icon="CheckSquareIcon" :size="setIconSize"/>
              {{ $t('work.btnSafetyCrossCheck') }}
            </b-button>
            <b-button variant="info" class="mb-1 mr-1" :block="setBtnBlock" :size="setBtnSize" v-if="stateBtnCreateCard">
              <feather-icon icon="CreditCardIcon" :size="setIconSize"/>
              {{ $t('work.btnCreateContractCard') }}
            </b-button>
          </div>

          <div class="align-self-stretch align-self-md-center order-1 order-md-2" v-if="[1, 5, 6].includes(userData.userGroupId)">
            <b-button variant="primary" class="mb-1" :block="setBtnBlock" :size="setBtnSize" @click="checkIn" v-if="stateBtnCheckIn">
              <feather-icon icon="LogInIcon" :size="setIconSize"/>
              {{ $t('work.btnCheckIn') }}
            </b-button>
            <b-button variant="primary" class="mb-1" :block="setBtnBlock" :size="setBtnSize" @click="checkOut" v-if="stateBtnCheckOut">
              <feather-icon icon="LogOutIcon" :size="setIconSize"/>
              {{ $t('work.btnCheckOut') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal
        ref="modal-checkin"
        cancel-variant="outline-secondary"
        :ok-title="$t('general.deleteConfirm')"
        hide-footer
        :cancel-title="$t('general.deleteCancel')"
        centered
        :title="$t('work.headerCheckIn')"
    >

      <b-form @submit.prevent="saveData">
        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form-group :label="$t('work.dateInspection')">
            <b-form-input :value="checkInDateShow" plaintext/>
          </b-form-group>

          <b-form-group :label="$t('work.photo')"></b-form-group>

          <b-form-group v-for="(row, key) in checkInPhotoLists" :key="'photo-row-' + key">
            <b-row align-v="center">
              <b-col cols="10">
                <validation-provider #default="{ errors }" :name="$t('work.photo')" rules="required" :vid="'photo' + key">
                  <b-form-file v-model="row.photoAttach" accept=".jpg, .gif, .pdf, .png"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <b-button variant="danger" size="sm" @click="deletePhoto(key)">
                  <feather-icon icon="XIcon"/>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <b-row class="my-2">
            <b-col class="text-right">
              <b-button variant="primary" @click="addPhoto">{{ $t('work.btnPhoto') }}</b-button>
            </b-col>
          </b-row>

          <b-form-group :label="$t('work.workerCheckIn')"></b-form-group>

          <b-form-group v-for="(row, key) in checkInWorkerLists" :key="'worker-row-' + key">
            <b-row v-if="row.workerDefault" align-v="center">
              <b-col cols="9" :style="{textDecoration: row.isDisabled ? 'line-through' : ''}">{{ row.workerFullName }}</b-col>
              <b-col cols="3" class="text-right">
                <div>
                  <b-form-checkbox checked="true" v-model="row.workerAttendance"
                                   class="custom-control-success" name="check-button" switch
                                   :disabled="row.isDisabled"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon"/>
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon"/>
                    </span>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <b-row align-v="center" v-else>
              <b-col cols="10">
                <validation-provider #default="{ errors }" :name="$t('work.workerCheckIn')" rules="required" :vid="'worker' + key">
                  <v-select v-model="row.userId" label="userFullName"
                            :options="optionWorkerLists" :reduce="option => option.userId"
                            :selectable="option => !optionWorkerListsDisabled.includes(option.userId)"
                            :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <b-button variant="danger" size="sm" @click="deleteWorker(key)">
                  <feather-icon icon="XIcon"/>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <b-row class="my-2">
            <b-col class="text-right">
              <b-button variant="primary" @click="addWorker">{{ $t('work.btnAddWorker') }}</b-button>
            </b-col>
          </b-row>

          <b-form-group :label="$t('work.inspectResult')">
            <b-form-radio-group v-model="checkInResult" :options="optionInspects" stacked class="radio-result"/>
          </b-form-group>

          <b-form-group :label="$t('work.approveComment')" v-if="checkInResult !== 'Y'">
            <b-form-input v-model="checkInComment"/>
          </b-form-group>

        </validation-observer>
      </b-form>

      <div class="mt-3 text-right">
        <b-button variant="outline-secondary" class="mb-1 mr-1" @click="handleCancel">
          {{ $t('general.deleteCancel') }}
        </b-button>
        <b-button variant="success" class="mb-1" @click="saveData" :disabled="isBtnDisabled">
          <b-spinner small v-if="isBtnDisabled"/>
          <feather-icon icon="SaveIcon" v-else/>
          {{ $t('general.btnSubmit') }}
        </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BModal, BForm, BFormFile, BFormCheckbox, BFormRadioGroup, BSpinner} from 'bootstrap-vue'
import moment from 'moment-timezone'
import {required} from '@validations'
import WorkViewDetail from "@/components/work/WorkViewDetail"
import WorkViewDateOfWork from "@/components/work/WorkViewDateOfWork"
import WorkViewContractor from "@/components/work/WorkViewContractor"
import WorkViewJsa from "@/components/work/WorkViewJsa"
import WorkViewActivity from "@/components/work/WorkViewActivity"
import WorkViewSafetyCheck from "@/components/work/WorkViewSafetyCheck"
import WorkViewCheckinHistory from "@/components/work/WorkViewCheckinHistory"
import {WorkService, FileStorageService} from "@/services"
import masterMixins from "@/mixins/masterMixins"
import formMixins from "@/mixins/formMixins"

const workService = new WorkService()
const fileStorageService = new FileStorageService()
moment.tz.setDefault("Asia/Bangkok")

export default {
  name: 'WorkFormOperation',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormFile,
    BSpinner,
    BFormCheckbox,
    BFormRadioGroup,
    BModal,
    WorkViewDetail,
    WorkViewDateOfWork,
    WorkViewContractor,
    WorkViewJsa,
    WorkViewActivity,
    WorkViewSafetyCheck,
    WorkViewCheckinHistory,
  },
  mixins: [masterMixins, formMixins],
  async created() {
    this.id = this.$route.params.id
    await this.getData()

    let currentDate = moment()
    this.checkInDate = currentDate.format('DD/MM/YYYY')
    this.checkInDateShow = currentDate.format('DD MMMM YYYY')

    this.optionInspects.push({text: this.$t('work.inspectOptionPass'), value: 'Y'})
    this.optionInspects.push({text: this.$t('work.inspectOptionNotPass'), value: 'N'})
    this.optionInspects.push({text: this.$t('work.inspectOptionCancel'), value: 'C'})

    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    userData: JSON.parse(localStorage.getItem('userData')),
    result: {},
    checkInDate: '',
    checkInDateShow: '',
    checkInPhotoLists: [],
    checkInWorkerLists: [],
    checkInResult: 'Y',
    checkInComment: '',
    optionWorkerLists: [],
    optionWorkerListsDisabled: [],
    optionInspects: [],
    totalSafetyRow: 0,

    checkInStatus: '',  // Y | N
    startDateExtend: ''
  }),
  computed: {
    setBtnBlock() {
      return this.$store.getters['app/currentBreakPoint'] === 'sm' || this.$store.getters['app/currentBreakPoint'] === 'xs'
    },
    setBtnSize() {
      if (this.$store.getters['app/currentBreakPoint'] === 'sm' || this.$store.getters['app/currentBreakPoint'] === 'xs') {
        return 'lg'
      } else {
        return 'md'
      }
    },
    setIconSize() {
      if (this.$store.getters['app/currentBreakPoint'] === 'sm' || this.$store.getters['app/currentBreakPoint'] === 'xs') {
        return '22'
      } else {
        return '14'
      }
    },
    stateBtnFinish() {
      return this.totalSafetyRow > 0
    },
    stateBtnExtend() {
      return !this.startDateExtend
    },
    stateBtnSafety() {
      return this.userData.userGroupId !== 4
    },
    stateBtnSafetyCrossCheck() {
      return this.userData.userGroupId === 4
    },
    stateBtnCheckIn() {
      return !(this.checkInStatus === 'Y' && this.checkInDate === this.checkInDate)
    },
    stateBtnCheckOut() {
      return this.checkInStatus === 'Y' && this.checkInDate === this.checkInDate
    },
    stateBtnCreateCard() {
      return false
    },
    checkoutIsSameDay() {
      return this.result.workLatestCheckout === moment().format('DD/MM/YYYY')
    }
  },
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.result = result
        this.checkInStatus = result.workLatestIsCheckIn
        this.startDateExtend = result.wStartDateExtend

        let alreadyUserId = []
        this.checkInWorkerLists = result.workerLists.map(worker => {
          alreadyUserId.push(worker.userId)
          return {
            userId: worker.userId,
            workerId: worker.workerId,
            workerFullName: worker.workerUserFullName,
            workerAttendance: worker.workerStatus === 'Y',
            isDisabled: worker.workerStatus !== 'Y',
            workerDefault: true
          }
        })

        let workerLists = await this.listUserContractor(result.companyId)
        this.optionWorkerLists = workerLists.filter(data => !alreadyUserId.includes(data.userId))
        this.setDisabledWorker()

        this.optionWorkerLists = this.optionWorkerLists.map(data => {
          if(this.optionWorkerListsDisabled.includes(data.userId)) {
            let setNewData = data
            setNewData.userFullName = `${setNewData.userFullName} (ไม่มีใบอนุญาต, หมดอายุ, หรือติด Blacklist)`

            return setNewData
          } else {
            return data

          }
        })

        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageWork'})
      }
    },
    handleCancel() {
      this.$refs['modal-checkin'].hide()
    },
    async saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let attachmentLists = []
          await Promise.all(
              this.checkInPhotoLists.map(async data => {
                let row = {}
                if (Object.entries(data.photoAttach).length > 0) {
                  let uploadResult = await fileStorageService.upload(data.photoAttach)
                  row.photoId = uploadResult.fileId
                }
                attachmentLists.push(row)
              })
          )

          let workerCheckinLists = this.checkInWorkerLists.map(item => {
            if (item.workerDefault) {
              return item
            } else {
              let findUser = this.optionWorkerLists.find(user => user.userId === item.userId)
              return {
                userId: item.userId,
                workerId: item.workerId,
                workerFullName: findUser.userFullName,
                workerAttendance: item.workerAttendance,
                workerDefault: item.workerDefault,
                isDisabled: item.isDisabled,
              }
            }
          })

          let actionData = {
            checkInPhotoLists: attachmentLists,
            checkInWorkerLists: workerCheckinLists,
            checkInResult: this.checkInResult,
            checkInComment: this.checkInComment,
          }
          workService.checkIn(this.id, actionData)
              .then(async ({isSuccess, data}) => {
                if (isSuccess) {
                  if (this.checkInResult === 'C') {
                    await this.$router.push({name: 'pageWork'})
                  } else {
                    await this.getData()
                    this.$refs['modal-checkin'].hide()
                  }

                  this.isBtnDisabled = false
                  this.$store.commit('main/setToastSuccess', {title: 'Checkin success', text: ''})
                  this.$router.push({name: 'pageWork'})
                } else {
                  this.$store.commit('main/setToastError', data)
                  this.isBtnDisabled = false
                }
              })
        }
      })
    },
    checkIn() {
      this.$refs['modal-checkin'].show()
    },
    checkOut() {
      this.$bvModal
          .msgBoxConfirm(this.$t('work.confirmCheckOutDescription'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if (value) {
              let queryResult = await workService.checkOut(this.id)
              let {isSuccess, data} = queryResult
              if (isSuccess) {
                this.isShowLoading = true
                this.$store.commit('main/setToastSuccess', {title: 'Checkout success', text: ''})
                await this.$router.push({name: 'pageWork', params: {id: this.id}})
              } else {
                this.$store.commit('main/setToastError', data)
              }
            }
          })
    },
    addPhoto() {
      this.checkInPhotoLists.push({
        photoAttach: [],
      })
    },
    deletePhoto(row) {
      this.checkInPhotoLists.splice(row, 1)
    },
    addWorker() {
      this.checkInWorkerLists.push({
        userId: '',
        workerId: '',
        workerFullName: '',
        workerAttendance: true,
        workerDefault: false,
        isDisabled: false
      })
    },
    deleteWorker(row) {
      this.checkInWorkerLists.splice(row, 1)
    },
    setDisabledWorker() {
      this.optionWorkerListsDisabled = this.optionWorkerLists
          .filter(data => data.userStatus !== 'Y' || !data.userLicenseEndDate ||
              !moment(moment(data.userLicenseEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')).isSameOrAfter(moment().format('YYYY-MM-DD')))
          .map(data => data.userId)
    },
    setTotalSafetyRow(row) {
      console.log('setTotalSafetyRow', row)
      this.totalSafetyRow = row
    }
  }
}
</script>

<style lang="scss">
.radio-result {
  .custom-control {
    margin-bottom: 0.7rem;
  }
}
</style>
